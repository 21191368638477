import React, { useState } from "react";
import "./PaymentMethod.css";
import Ethereum from "./../../Assets/Payment/Ethereum.svg";
import paytm from "./../../Assets/Payment/paytm.svg";
import gpay from "./../../Assets/Payment/GPay.svg";
import phnpe from "./../../Assets/Payment/phonepe.svg";
import Razorpay from "./../../Assets/Payment/razorpay.png";
import astropay from "./../../Assets/Payment/astroPay.svg";
import Bank from "./../../Assets/Payment/bank.png";
import { RxCross1 } from "react-icons/rx";
import { AiFillHome } from "react-icons/ai";
import coinpayments from "./../../Assets/Payment/coinpayments.svg";
import ERC20 from "./../../Assets/Payment/Tether-USD-ERC20-token.svg";
import TRC20 from "./../../Assets/Payment/Tether-USD-TRC20-token(1).svg";
import { useNavigate } from "react-router-dom";
import { toastFailed } from "../../Config/BasicInfo";
import BEP20 from "./../../Assets/Payment/Tether-usdt.png";
const PaymentMethod = ({ fun }) => {
  const [paymentType, setPaymentType] = useState("manual"); // Set initial type to "manual"
  const [formDepositAmount, setFormDepositAmount] = useState(300);
  const navigate = useNavigate();

  const paymentData = [
    { logo: paytm, height: "100%", width: "100%", type: "upi" },
    { logo: gpay, height: "100%", width: "100%", type: "upi" },
    { logo: phnpe, height: "100%", width: "100%", type: "upi" },
    { logo: astropay, height: "100%", width: "100%", type: "upi" },
    { logo: Bank, height: "60%", width: "60%", type: "bank" },
    { logo: TRC20, height: "100%", width: "100%", type: "TRC20" },
    { logo: coinpayments, type: "coinpayments" },
    { logo: Razorpay, type: "Razorpay" },
    { logo: Ethereum, height: "100%", width: "100%", type: "Ethereum" },
    { logo: ERC20, height: "100%", width: "100%", type: "ERC20" },
    { logo: BEP20, height: "100%", width: "100%", type: "BEP20" },
  ];

  const handleDeposit = () => {
    fun();
    if (formDepositAmount > 0) {
      const queryParams = new URLSearchParams({
        amount: formDepositAmount,
      }).toString();
      if (paymentType === "upi") {
        navigate(`/payment-upi?${queryParams}`);
      } else if (paymentType === "bank") {
        navigate(`/payment-bank?${queryParams}`);
      } else if (paymentType === "BEP20") {
        navigate("/fund");
      } else {
        toastFailed("Invalid payment method");
      }
    } else {
      toastFailed("Invalid amount");
    }
  };

  return (
    <section className="depositPaymentMethod">
      <div className="depositPaymentMethodContent">
        <div id="depositPaymentMethodContentHeading">
          <p>Deposit</p>
          <i onClick={() => fun()}>
            <RxCross1 />
          </i>
        </div>
        <div style={{ position: "relative" }}>
          <h5 id="depositPaymentMethodContentSubHeading">
            Select payment method
          </h5>
          {paymentType !== "manual" && (
            <i id="paymentBackButton" onClick={() => setPaymentType("manual")}>
              <AiFillHome />
            </i>
          )}
        </div>
        <section className="paymentsLogosSection">
          {paymentType === "manual" && (
            <div className="paymentsLogos paymentsLogosCustomLogo">
              {paymentData.map((x, index) => (
                <div
                  key={index}
                  onClick={() => {
                    // Allow click only for 'upi' and 'bank'
                    if (
                      x.type === "upi" ||
                      x.type === "bank" ||
                      x.type === "BEP20"
                    ) {
                      setPaymentType(x.type);
                    } else {
                      toastFailed("Currently Unavailable");
                    }
                  }}
                >
                  <img
                    src={x.logo}
                    style={{ width: x.width, height: x.height }}
                    alt={x.type}
                  />
                </div>
              ))}
            </div>
          )}
          {(paymentType === "upi" || paymentType === "bank") && (
            <div className="selectAmontBeforeTransaction">
              <h1>DEPOSIT</h1>
              <p>Please choose or enter deposit amount</p>
              <div className="chooseAmountFromTabs">
                <span onClick={() => setFormDepositAmount(300)}>300 INR</span>
                <span onClick={() => setFormDepositAmount(500)}>500 INR</span>
                <span onClick={() => setFormDepositAmount(1000)}>1000 INR</span>
                <span onClick={() => setFormDepositAmount(1500)}>1500 INR</span>
                <span onClick={() => setFormDepositAmount(3000)}>3000 INR</span>
                <span onClick={() => setFormDepositAmount(5000)}>5000 INR</span>
              </div>
              <p className="m-0 mt-3">Enter amount</p>
              <div id="selectAmontBeforeTransactionInput">
                <input
                  type="number"
                  value={formDepositAmount}
                  onChange={(e) =>
                    e.target.value <= 100000 &&
                    setFormDepositAmount(e.target.value)
                  }
                />
                <span>INR</span>
              </div>
              <p>
                Max. deposit: <h5>100000 INR</h5>
              </p>
              <button
                className="btnPrimary mb-4"
                style={{ borderRadius: "5px" }}
                onClick={handleDeposit}
              >
                DEPOSIT
              </button>
            </div>
          )}
          {paymentType === "BEP20" && (
            <div>
              <button className="btnPrimary mb-4" onClick={handleDeposit}>
                Proceed
              </button>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default PaymentMethod;
