import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import "./Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "./Redux/StatusState";
import GamesPlayer from "./Pages/GamesPlayer/GamesPlayer";
import FullscreenDiv from "./Pages/TestCode";
import { fetchConfig } from "./Redux/ConfigSlice";
import SomeComponent from "./TestPages/Test1";
import PaymentWithBank from "./Components/PaymentWithBank/PaymentWithBank";
import UploadPaymentProof from "./Pages/UploadPaymentProof/UploadPaymentProof";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import PaymentWithUPI from "./Components/PaymentWithUPI/PaymentWithUPI";
import "react-loading-skeleton/dist/skeleton.css";
import ProviderGames from "./Pages/ProviderGames/ProviderGames";
import MobileHeader from "./Components/Header/MobileHeader";
import Header from "./Components/Header/Header";
import Policy from "./Pages/Documentation/Policy";
import BidsHistory from "./Pages/BidsHistory/BidsHistory";
import Withdrawal from "./Pages/Withdrawal/Withdrawal";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import DepositHistory from "./Pages/DepositHistory/DepositHistory";
import WithdrawHistory from "./Pages/WithdrawHistory/WithdrawHistory";
import { setRegisterDisplay } from "./Redux/RegisterSlice";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Components/Profile/Profile";
import DirectTeam from "./Pages/DirectTeam/DirectTeam";
import GeneraTionTeam from "./Pages/GenerationTeam/GenerationTeam";
import Incomes from "./Pages/Incomes/Incomes";
import AccountDetails from "./Pages/BankDetails/AccountDetails";
import UpiDetails from "./Pages/UpiDetails/UpiDetails";
import WebDetails from "./Pages/Web3/Web3";
import Referral from "./Components/Referral/Referral";
import ForgotPassword from "./Pages/Forgot/Forgot";
import PaymentWithUSDT from "./Components/PaymentWithUSDT/PaymentWithUSDT";
import Fund from "./Pages/Fund/Fund";
const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const configStatus = useSelector((state) => state.config.status);
  const authToken = useSelector((state) => state.userStatus.authToken);
  const isShowLogin = useSelector((state) => state.loginDisplay.value);
  const isShowRegister = useSelector((state) => state.registerDisplay.value);
  const isShowProfile = useSelector((state) => state.forgotDisplay.value);

  useEffect(() => {
    const token = localStorage.getItem("token");
    dispatch(setAuthToken(token));
  }, [dispatch]);

  useEffect(() => {
    if (configStatus === "idle") {
      dispatch(fetchConfig());
    }
  }, [dispatch, configStatus]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    if (ref) {
      dispatch(setRegisterDisplay(true));
    }
  }, []);

  // Define paths where headers should be hidden
  const noHeaderPaths = ["/games"]; // Add the paths where you don't want the headers

  const shouldShowHeaders = !noHeaderPaths.includes(location.pathname);

  return (
    <>
      <ToastContainer draggable={true} closeOnClick={true} theme="colored" />
      {isShowRegister && <Register />}
      {isShowLogin && <Login />}
      {isShowProfile && <ForgotPassword />}
      {shouldShowHeaders && <MobileHeader />}
      {shouldShowHeaders && <Header />}
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/provider/:providerName" element={<ProviderGames />} />
        <Route path="/policies/:policyName" element={<Policy />} />
        <Route path="/test2" element={<SomeComponent />} />
        {authToken?.length > 0 && (
          <>
            <Route path="/games" element={<GamesPlayer />} />
            <Route path="/payment-bank" element={<PaymentWithBank />} />
            <Route path="/payment-upi" element={<PaymentWithUPI />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboardProfile" element={<ProfilePage />} />
            <Route path="/direct-team" element={<DirectTeam />} />
            <Route path="/generation-team" element={<GeneraTionTeam />} />
            <Route path="/incomes" element={<Incomes />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/history-deposit" element={<DepositHistory />} />
            <Route path="/history-withdraw" element={<WithdrawHistory />} />
            <Route path="/bets" element={<BidsHistory />} />
            <Route path="/payment-proof" element={<UploadPaymentProof />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/test" element={<FullscreenDiv />} />
            <Route path="/account-details" element={<AccountDetails />} />
            <Route path="/upi-details" element={<UpiDetails />} />
            <Route path="/web-details" element={<WebDetails />} />
            <Route path="/payment-bep20" element={<PaymentWithUSDT />} />
            <Route path="/fund" element={<Fund />} />
          </>
        )}
      </Routes>
      {/* <Footer/> */}
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
