const baseurl = process.env.REACT_APP_API_URL;

export const ApiPaths = {
  getAllGames: `/get_all_games`,
  login: `/login`,
  register: `/register`,
  getProfile: "/get_profile",
  paymentRequest: "/payment-request",
  getPaymentMethod: "/get-payment-method",
  getWallets: "/get_wallets",
  getPaymentTransaction: "/get-payment-transaction",
  getProviders: "/get_all_providers",
  getDocumentation: "/get_page",
  changePassword: "/update-password",
  withdraw: "/withdraw",
  getTopGames: "/get-top-games",
  getTeams: "/get_all_team",
  getPackages: "/get-all-package",
  addBankDetails: "/add-bank-details",
  deleteBankDetails: "/delete-bank-details",
  defaultBankDetails: "/set-default-bank-details",
  getBankDetails: "/get-bank-details",
  addUpiDetails: "/add-upi-details",
  deleteUpiDetails: "/delete-upi-details ",
  defaultUpiDetails: "/set-default-upi-details",
  getUpiDetails: "/get-upi-details",
  getWebDetails: "/get-web3-details",
  addWebDetails: "/add-web3-details",
  deleteWebDetails: "/delete-web3-details",
  defaultWebDetails: "/set-default-web3-details",
  getAccountDetails: "/get-user-payment-details",
  sendOtp: "/send-otp",
  forgotPassword: "/forgot-password",
  getPaymentMode: "/sendcryp-payment-request",
  fundRequest: "/manual-payment-status",
};
