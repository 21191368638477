import React, { useEffect, useState } from 'react';
import './Home.css';
import Banner from '../../Components/Banner/Banner';
import { Container } from 'react-bootstrap';
import ribbon from './../../Assets/Images/ribbon.svg';
import woman from './../../Assets/Images/woman.png';
import { MdKeyboardArrowRight } from "react-icons/md";
import slotGirl from './../../Assets/Images/slotGirl.png';
import cubes from './../../Assets/Images/cubes.png';
import redPlane from './../../Assets/Images/red-plane.png';
import x100 from './../../Assets/Images/x100.png';
import men from './../../Assets/Images/men.png';
import trippleSeven from './../../Assets/Images/777.png';
import Rocket from './../../Assets/Images/rocket.png';
import Gift from './../../Assets/Images/gift-box.png';
import chip from './../../Assets/Images/chip.png';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAxiosHelper from '../../Common/AxiosHalper';
import Loader from '../../Components/Loader/Loader';
import { setLoginDisplay } from '../../Redux/LoginSlice';
import Footer from '../../Components/Footer/Footer';
import { ApiPaths } from '../../Config/ApiPaths';
import { BasicInfo } from '../../Config/BasicInfo';
import ImageLoader from '../../Components/ImageLoader/ImageLoader';
import BonusBanner1 from "./../../Assets/Images/bonusBanner1.jpg"
import BonusBanner2 from "./../../Assets/Images/bonusBanner2.jpg";
import "react-multi-carousel/lib/styles.css";
import { setDashboardProviders, setFirstGameSection, setSecondGameSection, setThirdGameSection, setTopGames } from '../../Redux/DashboardDataSlice';
import evoLobby from './../../Assets/Images/evoLobby.jpg'
const Home = () => {
    const { AxiosGet, AxiosPost } = useAxiosHelper();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.userStatus.authToken);
    const dashboardProviders = useSelector((state) => state.dashboardData.dashboardProviders);
    const firstGameSection = useSelector((state) => state.dashboardData.firstGameSection);
    const secondGameSection = useSelector((state) => state.dashboardData.secondGameSection);
    const thirdGameSection = useSelector((state) => state.dashboardData.thirdGameSection);
    const topGames = useSelector((state) => state.dashboardData.topGames);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        checkData();
    }, []);

    const checkData = () => {
        if (dashboardProviders?.length === 0 || firstGameSection?.length === 0 || secondGameSection?.length === 0 || thirdGameSection?.length === 0) {
            FetchData();
        }
    };

    const FetchData = async () => {
        try {
            const body1 = { "type": "live dealer", "count": 10 };
            const body2 = { "type": "slots", "count": 12 };
            const body3 = { "type": "roulette", "count": 12 };

            const [gameRes1, gameRes2, gameRes3, providers, tempTopGames] = await Promise.all([
                AxiosPost(ApiPaths.getAllGames, body1),
                AxiosPost(ApiPaths.getAllGames, body2),
                AxiosPost(ApiPaths.getAllGames, body3),
                AxiosGet(ApiPaths.getProviders),
                AxiosGet(ApiPaths.getTopGames)
            ]);
            console.log(
                "tempTopGames", tempTopGames?.topgame
            )
            dispatch(setFirstGameSection(gameRes1));
            dispatch(setSecondGameSection(gameRes2));
            dispatch(setThirdGameSection(gameRes3));
            dispatch(setDashboardProviders(providers))
            dispatch(setTopGames(tempTopGames?.topgame))
        } catch (e) {
            console.error("Error fetching data", e);
        }
    };

    const getGame = async (data) => {
        let userData;
        BasicInfo.isDebug && console.log("authToken", authToken);

        const cachedData = localStorage.getItem('userProfile');
        BasicInfo.isDebug && console.log('Retrieved cachedData:', cachedData);

        if (authToken && cachedData) {
            userData = await JSON.parse(cachedData);
            BasicInfo.isDebug && console.log("userData", userData)
            try {
                setLoading(true);
                const body = {
                    game_uuid: data?.g_uid,
                    username: userData?.username,
                    name: userData?.name,
                    currency: "INR",
                };
                const res = await AxiosPost("/game_init", body);
                BasicInfo.isDebug && console.log("res", res);
                let link = res?.data?.url;
                setLoading(false);
                navigate('/games', { state: { url: link } });
            } catch (error) {
                console.error("Error initializing game", error);
                setLoading(false);
            }
        } else {
            dispatch(setLoginDisplay(true));
        }
    };

    return (
        <>
            <section className="homePage">
                {loading && <Loader />}
                <section className="homePageData container">
                    <Container>
                        <Banner />
                        <div className="iconHeading">
                            <img src={ribbon} alt="ribbon" />
                            <p>Top Sections</p>
                        </div>
                        <div className="topGames" >

                            {dashboardProviders?.length > 0 ? dashboardProviders?.map((x, i) => (
                                <div key={i} onClick={() => navigate(`/provider/${x?.provider}`)}>
                                    <div id='providerLogo'>
                                        <img src={x?.provider_logo} alt="" />
                                    </div>
                                    <p>{x?.provider}</p>
                                </div>
                            )) :
                                Array.from({ length: 12 }).map((_, i) => {
                                    return <div key={i} id='providerSkeleton'>
                                        <ImageLoader />
                                    </div>
                                })
                            }
                        </div>
                        <div className="iconHeadingAndLink">
                            <div className="iconHeading">
                                <img src={woman} alt="woman" />
                                <p>Live Dealers</p>
                            </div>
                            <a className='headingViewLink'>
                                <h5>More Live Dealers</h5>
                                <i><MdKeyboardArrowRight /></i>
                            </a>
                        </div>
                        <div className="homeGameSections">
                            <div className='homeGameSectionsLeft'>
                                <div className="homeGameSectionsDetailsDiv bgBlue">
                                    <p id="cardSmallHeading">AWESOME</p>
                                    <p id="cardBigHeading">Live Dealers</p>
                                    <div>
                                        <div className="gameCardDetails">
                                            <div>
                                                <div>
                                                    <h5>10,885</h5>
                                                    <span>LIVE</span>
                                                </div>
                                                <p>Total rounds for today</p>
                                            </div>
                                            <div>
                                                <h5>201</h5>
                                                <p>Active tables</p>
                                            </div>
                                            <div>
                                                <h5>Bet on teen patti</h5>
                                                <p>Top table/games</p>
                                            </div>
                                            <div>
                                                <h5>50,907,509 INR</h5>
                                                <p>highest win on the day</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={cubes} alt="" className='cardImage1' />
                                    <img src={slotGirl} alt="" className='cardImage2' />
                                </div>
                            </div>
                            <div className='homeGameSectionsRight'>
                                <div className='homeGameSectionsGames'>
                                    {Array.isArray(topGames) && topGames?.map((x, i) => (
                                        <div onClick={() => getGame(x)} className='allGameThumbnail' key={i}>
                                            {
                                                x?.image != null && x?.image != '' ?
                                                    <img src={x?.image} alt="" /> :
                                                    <img src={BasicInfo.DefaultGameBanner} alt="" />
                                            }
                                        </div>
                                    ))}
                                    {firstGameSection?.length > 0 ? firstGameSection?.map((x, i) => (
                                        <div onClick={() => getGame(x)} className='allGameThumbnail' key={i}>
                                            {
                                                x?.image != null && x?.image != '' ?
                                                    <img src={x?.image} alt="" /> :
                                                    <img src={BasicInfo.DefaultGameBanner} alt="" />
                                            }
                                        </div>
                                    )) : Array.from({ length: 12 }).map((x, i) => {
                                        return <div className='allGameThumbnail'>
                                            <ImageLoader />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="iconHeadingAndLink">
                            <div className="iconHeading">
                                <img src={chip} alt="chip" />
                                <p>Roulette Games</p>
                            </div>
                            <a className='headingViewLink'>
                                <h5>More Roulette Games</h5>
                                <i><MdKeyboardArrowRight /></i>
                            </a>
                        </div>
                        <div className="homeGameSections">
                            <div className='homeGameSectionsLeft'>
                                <div className="homeGameSectionsDetailsDiv bgYellow">
                                    <p id="cardSmallHeading">THE MOST</p>
                                    <p id="cardBigHeading">Roulette Games</p>
                                    <div>
                                        <div className="gameCardDetails">
                                            <div>
                                                <div>
                                                    <h5>10,885</h5>
                                                    <span>LIVE</span>
                                                </div>
                                                <p>Total rounds for today</p>
                                            </div>
                                            <div>
                                                <h5>201</h5>
                                                <p>Active tables</p>
                                            </div>
                                            <div>
                                                <h5>Bet on teen patti</h5>
                                                <p>Top table/games</p>
                                            </div>
                                            <div>
                                                <h5>50,907,509 INR</h5>
                                                <p>highest win on the day</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={trippleSeven} alt="" className='cardImage1' />
                                    <img src={men} alt="" className='cardImage2' style={{ bottom: "-4%", left: '-5%' }} />
                                </div>
                            </div>
                            <div className='homeGameSectionsRight'>
                                <div className='homeGameSectionsGames'>
                                    {thirdGameSection?.length > 0 ? thirdGameSection?.map((x, i) => (
                                        <div onClick={() => getGame(x)} className='allGameThumbnail' key={i}>
                                            {
                                                x?.image != null && x?.image != '' ?
                                                    <img src={x?.image} alt="" /> :
                                                    <img src={BasicInfo.DefaultGameBanner} alt="" />
                                            }
                                        </div>
                                    )) : Array.from({ length: 12 }).map((x, i) => {
                                        return <div className='allGameThumbnail'>
                                            <ImageLoader />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="iconHeadingAndLink">
                            <div className="iconHeading">
                                <img src={Rocket} alt="Rocket" />
                                <p>Slots Games</p>
                            </div>
                            <a className='headingViewLink'>
                                <h5>More Slots Games</h5>
                                <i><MdKeyboardArrowRight /></i>
                            </a>
                        </div>
                        <div className="homeGameSections">
                            <div className='homeGameSectionsLeft'>
                                <div className="homeGameSectionsDetailsDiv bgRed">
                                    <p id="cardSmallHeading">AWESOME</p>
                                    <p id="cardBigHeading">Slots Games</p>
                                    <div>
                                        <div className="gameCardDetails">
                                            <div>
                                                <div>
                                                    <h5>10,885</h5>
                                                    <span>LIVE</span>
                                                </div>
                                                <p>Total rounds for today</p>
                                            </div>
                                            <div>
                                                <h5>201</h5>
                                                <p>Active tables</p>
                                            </div>
                                            <div>
                                                <h5>Bet on teen patti</h5>
                                                <p>Top table/games</p>
                                            </div>
                                            <div>
                                                <h5>50,907,509 INR</h5>
                                                <p>highest win on the day</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={x100} alt="" className='cardImage1' />
                                    <img src={redPlane} alt="" className='cardImage2' style={{ bottom: "-15%", left: '-15%' }} />
                                </div>
                            </div>
                            <div className='homeGameSectionsRight'>
                                <div className='homeGameSectionsGames'>
                                    {secondGameSection?.length > 0 ? secondGameSection?.map((x, i) => (
                                        <div onClick={() => getGame(x)} className='allGameThumbnail' key={i}>
                                            {
                                                x?.image != null && x?.image != '' ?
                                                    <img src={x?.image} alt="" /> :
                                                    <img src={BasicInfo.DefaultGameBanner} alt="" />
                                            }
                                        </div>
                                    )) : Array.from({ length: 12 }).map((x, i) => {
                                        return <div className='allGameThumbnail'>
                                            <ImageLoader />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="iconHeadingAndLink">
                            <div className="iconHeading">
                                <img src={Gift} alt="chip" />
                                <p>Bonuses</p>
                            </div>
                            <a className='headingViewLink'>
                                <h5>More Bonuses</h5>
                                <i><MdKeyboardArrowRight /></i>
                            </a>
                        </div>

                        <section className="bonusSection">
                            <div>
                                <div className='bonusSectionImageDiv'> <img src={BonusBanner1} alt="" /></div>
                                <div className='bonusSectionImageDiv'> <img src={BonusBanner2} alt="" /></div>
                            </div>
                        </section>
                        {/* <div className="iconHeadingAndLink">
                            <div className="iconHeading">
                                <img src={Review} alt="chip" />
                                <p>Testimonials</p>
                            </div>
                        </div>
                        <section className="testimonialSection">
                            <Carousel
                                arrows={false}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                // deviceType={deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {
                                    Array.from({ length: 6 }).map((_, i) => {
                                        return <div className="testimonialDiv">
                                            <div>
                                                <img src={Boy} alt="user" />
                                                <div>
                                                    <h1>John Doe</h1>
                                                    <div id='ratingDiv'>
                                                        <img src={Star} alt="" />
                                                        <img src={Star} alt="" />
                                                        <img src={Star} alt="" />
                                                        <img src={Star} alt="" />
                                                        <img src={Star} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p>This gambling app is exciting, user-friendly, and secure. Its intuitive design and rewarding gameplay make it highly enjoyable. Highly recommended!</p>
                                        </div>
                                    })
                                }
                            </Carousel>;
                        </section> */}


                    </Container>
                </section>
                <Footer />
            </section>
        </>
    );
};

export default Home;
