import React from 'react';
import './Footer.css';
import Facebook from './../../Assets/Images/facebook.png'
import twitter from './../../Assets/Images/twitter.png'
import instagram from './../../Assets/Images/instagram.png'
import { BasicInfo } from '../../Config/BasicInfo';
import { Col, Row } from 'react-bootstrap';
import Adult from './../../Assets/Images/adult.png';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();
    return (
        <section className='myFooter'>
            <Row>
                <Col lg="4">
                    <div className="footersLinks">
                        <a onClick={() => navigate('/policies/gamepolicy')}>Game Policy</a>
                        <a onClick={() => navigate('/policies/privacypolicy')}>Privacy Policy</a>
                        <a onClick={() => navigate('/policies/faq')}>FAQ</a>
                        <a onClick={() => navigate('/policies/terms')}>Terms and conditions</a>
                        <a>News</a>
                        <a>Responsible Gaming Policy</a>
                        <a>Bonus Terms</a>
                        <a>Rules for Specific sports</a>
                        <a>AML/KYC Policy</a>
                        <a>Affiliate program</a>
                        <a>Gamepitara Odds & Predictions</a>
                    </div>
                </Col>
                <Col lg="6" className='d-flex justify-content-start' id="adultLogoDown">
                    <div>
                        <div className="footerSocialMedia">
                            <img src={Facebook} alt="Facebook" />
                            <img src={twitter} alt="X" />
                            <img src={instagram} alt="Instagram" />
                        </div>
                        <p id='copyRightLine'>Copyright © 2018 {BasicInfo.projectName}. All rights are reserved and protected by law</p>
                    </div>
                </Col>
                <Col lg="2" id='footerAdultLogo'>
                    <img src={Adult} alt="18+" id='adult' className='m-0' />
                </Col>
            </Row>
        </section>
    )
}

export default Footer