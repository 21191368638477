import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Gift from "./../../Assets/Images/gift.png";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import BannerImage from "./../../Assets/Images/registerImg.jpg";
import { useDispatch } from "react-redux";
import { setLoginDisplay } from "../../Redux/LoginSlice";
import { setRegisterDisplay } from "../../Redux/RegisterSlice";
import { ApiPaths } from "../../Config/ApiPaths";
import { setForgotDisplay } from "../../Redux/Forgot";

import RotateLoader from "../../Components/RotateLoader/RotateLoader";
import { toast } from "react-toastify";
import { BasicInfo } from "../../Config/BasicInfo";
import { setAuthToken } from "../../Redux/StatusState";
import useAxiosHelper from "../../Common/AxiosHalper";
import Adult from "./../../Assets/Images/adult.png";
import "./Login.css";
const Login = () => {
  const dispatch = useDispatch();
  const { AxiosPost } = useAxiosHelper();
  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [formUsername, setFormUsername] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { value: "1", label: "Sport Welcome Pack 700%" },
    { value: "2", label: "Crash Welcome Pack 700%" },
    { value: "3", label: "Play Without Bonus" },
  ];
  function ChnageFun() {
    dispatch(setLoginDisplay(false));
    dispatch(setRegisterDisplay(true));
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        Login();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [formUsername, formPassword]);

  async function Login() {
    if (formUsername?.length > 0 && formPassword?.length > 0) {
      setIsLoading(true);
      try {
        const body = {
          password: formPassword,
          username: formUsername,
        };
        const res = await AxiosPost(ApiPaths.login, body);
        // console.log("login", res);
        if (res.status == 200) {
          toastSuccess(res?.message);
          dispatch(setAuthToken(res?.token));
          localStorage.setItem("token", res?.token);
          localStorage.setItem("userProfile", JSON.stringify(res?.user));
          dispatch(setLoginDisplay(false));
        }
        setIsLoading(false);
      } catch (error) {
        toastFailed(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      toastFailed("Invalid Data");
    }
  }
  return (
    <section className="registerSection">
      <div className="registerData" id="loginSection">
        <div className="registerLeft">
          <img src={BannerImage} alt="banner" />
          <div className="registerLeftContent">
            <h1>Welcome Back</h1>
            <p>On the best innovate sportsbook and casino</p>
            <img src={BasicInfo.logo} alt="Logo" />
          </div>
        </div>
        <div className="registerRight">
          <div
            style={{
              position: "relative",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <i
                className="d-flex align-items-center justify-content-end mb-2"
                id="registerCrossIcon"
                onClick={() => dispatch(setLoginDisplay(false))}
              >
                <RxCross2 />
              </i>
              <div id="registerGiftDiv " className="mb-3 mt-4">
                <div id="registerGiftDivIcon">
                  <div>
                    <img src={Gift} alt="gift" />
                  </div>
                  <p>
                    10% extra on first
                    <br /> DEPOSIT
                  </p>
                </div>
              </div>
              <input
                type="text"
                placeholder="Username"
                className="mainInput mb-12"
                value={formUsername}
                onChange={(e) => setFormUsername(e.target.value)}
              />

              <div id="registerPasswordDiv" className="mb-12">
                <input
                  type={passwordVisiblity == true ? "text" : "password"}
                  placeholder="Password"
                  className="mainInput"
                  value={formPassword}
                  onChange={(e) => setFormPassword(e.target.value)}
                />
                <i onClick={() => setPasswordVisiblity(!passwordVisiblity)}>
                  {passwordVisiblity == true ? (
                    <IoEyeSharp />
                  ) : (
                    <IoEyeOffSharp />
                  )}
                </i>
              </div>
              <label
                for="topping1"
                className="registerTermCondition m-0 mb-2"
                style={{ width: "100%" }}
              >
                Don't remember?{" "}
                <Link onClick={() => dispatch(setForgotDisplay(true))}>
                  Recover Password
                </Link>
              </label>
              <img src={Adult} alt="18+" id="adult" />
            </div>
            <div>
              <div className="alreadyRegister mb-2">
                <p>Don't have an account?</p>
                <Link onClick={ChnageFun}>Sign up</Link>
              </div>
              {isLoading ? (
                <RotateLoader />
              ) : (
                <button
                  className="btnPrimary"
                  onClick={Login}
                  style={{ fontSize: "14px" }}
                >
                  SIGN IN
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
